import React, { useState, useContext, useEffect } from "react";
import TrackContext from "../lib/trackContext";
import ReactPlayer from "react-player";
import { graphql, useStaticQuery, Link } from "gatsby";
import NowPlaying from "./NowPlaying";

const gatsbyQuery = graphql`
  query AudioPlayerQuery {
    allPerformanceRecording {
      nodes {
        id
        performanceKey
        source
        childrenAudioFile {
          url
        }
        song {
          title
          slug
          id
        }

        performance {
          date
          slug
          location {
            name
          }
        }
      }
    }
  }
`;
const getCollectionNodes = ({ allNodes, currentNode, collection }) => {
  if (collection === "performances") {
    return allNodes.nodes
      .filter((node) => node.performanceKey === currentNode.performanceKey)
      .filter((node) => node.source === currentNode.source);
  }
  if (collection === "songs") {
    return allNodes.nodes
      .filter((node) => node.song)
      .filter((node) => node.song.id === currentNode.song.id)
      .filter((node) => node.source === currentNode.source);
  }
};

export default () => {
  const context = useContext(TrackContext);
  const [isPlaying, setPlaying] = useState(false);
  useEffect(() => setPlaying(true), [context.data.id]);
  const queryData = useStaticQuery(gatsbyQuery);
  if (!context.data.id) {
    return <div />;
  }
  const performanceRecording = queryData.allPerformanceRecording.nodes.find(
    (node) => node.id === context.data.id
  );

  const onNext = () => {
    const collectionNodes = getCollectionNodes({
      collection: context.data.collection,
      allNodes: queryData.allPerformanceRecording,
      currentNode: performanceRecording,
    });
    const currentIndex = collectionNodes.findIndex(
      (node) => node.id === context.data.id
    );
    const nextIndex =
      currentIndex + 1 === collectionNodes.length ? 0 : currentIndex + 1;
    context.setTrackContext({
      ...context.data,
      id: collectionNodes[nextIndex].id,
    });
  };
  return (
    <>
      <NowPlaying
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        isPlaying={isPlaying}
        currentRecording={performanceRecording}
        onPrev={() => {
          const collectionNodes = getCollectionNodes({
            collection: context.data.collection,
            allNodes: queryData.allPerformanceRecording,
            currentNode: performanceRecording,
          });
          const currentIndex = collectionNodes.findIndex(
            (node) => node.id === context.data.id
          );
          const prevIndex =
            currentIndex - 1 === -1
              ? collectionNodes.length - 1
              : currentIndex - 1;
          context.setTrackContext({
            ...context.data,
            id: collectionNodes[prevIndex].id,
          });
        }}
        onNext={onNext}
      />
      <ReactPlayer
        onEnded={onNext}
        width={0}
        height={0}
        playing={isPlaying}
        url={performanceRecording.childrenAudioFile[0].url}
      />
    </>
  );
};
