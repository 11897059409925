// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-core-src-pages-discography-tsx": () => import("./../../gatsby-theme-core/src/pages/discography.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-pages-discography-tsx" */),
  "component---gatsby-theme-core-src-pages-index-tsx": () => import("./../../gatsby-theme-core/src/pages/index.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-pages-index-tsx" */),
  "component---gatsby-theme-core-src-pages-performances-tsx": () => import("./../../gatsby-theme-core/src/pages/performances.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-pages-performances-tsx" */),
  "component---gatsby-theme-core-src-pages-posts-tsx": () => import("./../../gatsby-theme-core/src/pages/posts.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-pages-posts-tsx" */),
  "component---gatsby-theme-core-src-pages-songs-tsx": () => import("./../../gatsby-theme-core/src/pages/songs.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-pages-songs-tsx" */),
  "component---gatsby-theme-core-src-templates-discography-tsx": () => import("./../../gatsby-theme-core/src/templates/discography.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-templates-discography-tsx" */),
  "component---gatsby-theme-core-src-templates-performances-tsx": () => import("./../../gatsby-theme-core/src/templates/performances.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-templates-performances-tsx" */),
  "component---gatsby-theme-core-src-templates-posts-tsx": () => import("./../../gatsby-theme-core/src/templates/posts.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-templates-posts-tsx" */),
  "component---gatsby-theme-core-src-templates-songs-tsx": () => import("./../../gatsby-theme-core/src/templates/songs.tsx" /* webpackChunkName: "component---gatsby-theme-core-src-templates-songs-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

