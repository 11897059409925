import React, { useState } from "react";

import AudioPlayer from "../components/AudioPlayer";
import TrackContext from "../lib/trackContext";
import { Helmet } from "react-helmet";

export default ({ element }) => {
  const [trackContext, setTrackContext] = useState({
    id: null,
    performance: null,
    collection: null,
  });
  return (
    <TrackContext.Provider
      value={{
        data: trackContext,
        setTrackContext,
      }}
    >
      <Helmet>
        <meta name="viewport" content="initial-scale=1" />
      </Helmet>
      {element}
      <AudioPlayer />
    </TrackContext.Provider>
  );
};
