import React from "react"
import { Breadcrumb, Typography, Affix, Row, Col } from "antd"
import {
  StepBackwardOutlined,
  StepForwardOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
} from "@ant-design/icons"
import { Link } from "gatsby"
const { Title } = Typography
import { NowPlayingProps } from "@richsoni/gatsby-theme-core/src/lib"

export default ({
  onPrev,
  onNext,
  currentRecording,
  onPlay,
  onPause,
  isPlaying,
}: NowPlayingProps) => {
  return (
    <Affix offsetBottom={0}>
      <div
        style={{
          width: "100%",
          background: "white",
          borderTop: "1px solid black",
        }}
      >
        <Row align="middle" justify="center" gutter={10}>
          <Col>
            <Title style={{ margin: 0 }}>
              <StepBackwardOutlined onClick={onPrev} />
            </Title>
          </Col>
          <Col>
            <Title style={{ margin: 0 }}>
              {isPlaying ? (
                <PauseCircleFilled onClick={onPause} />
              ) : (
                <PlayCircleFilled onClick={onPlay} />
              )}
            </Title>
          </Col>
          <Col>
            <Title style={{ margin: 0 }}>
              <StepForwardOutlined onClick={onNext} />
            </Title>
          </Col>
          <Col>
            <strong>Now Playing:</strong>
          </Col>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={currentRecording.performance.slug}>
                  {currentRecording.performance.date}{" "}
                  {currentRecording.performance.location.name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={currentRecording.song.slug}>
                  {currentRecording.song.title}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Affix>
  )
}
