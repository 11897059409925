import React from "react";

export interface TrackContext {
  data: {
    id: null | string;
    performance: null | string;
  };

  setTrackContext: (x: { id: string; collection: string }) => any;
}

export default React.createContext({
  data: {
    id: null,
    performance: null,
    collection: null,
  },
  setTrackContext: (x) => {},
});
