module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"hTznZVcdLfk5TUO9PcTfYvcE230NgJkm","trackPage":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"richsoni.com","short_name":"richsoni.com","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"/opt/build/repo/packages/cms/content/web-assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d0042f69c36c282112e8a2e58576013c"},
    },{
      plugin: require('../../gatsby-theme-core/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
